import React from 'react'

export default function Loading() {
    const css = {
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)",
        zIndex:"99999999",
        display:"none"
    }
  return (
    <div className='loading' style={css}>
        <img src='/loading.gif' className='img-fluid'></img>
    </div>
  )
}
