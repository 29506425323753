import React from 'react'
import { staticurl } from './apiurl'

export default function Footer() {
  return (
    <div className='footer'>
        <div className='footer-content'>
        <div className='container pb-3'>
            <div className='row row-cols-lg-2 row-cols-md-2 row-cols-1'>
                <div className='col'>
                    <div className='logo p-3'>
                        <img src="/logo.png" style={{width:"150px"}} alt="logo"/>
                    </div>
                    <div className='px-3'>
                        Follow Us:
                        <div className='social-media-links'>
                            <span className='media-links'>
                                <a href='https://www.facebook.com/profile.php?id=100064544566071' target='_blank'>
                                  <i className="fa-brands fa-facebook"></i>
                                </a>
                            </span>
                            <span className='media-links'>
                              <a href='https://www.instagram.com/planetmusic.np/' target='_blank'>
                                <i className="fa-brands fa-instagram"></i>

                              </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='col  mt-3'>
                      <h5>Contacts</h5>
                      {/* <div className=''>
                        <i className='fa-solid fa-location-dot mx-2'></i>
                        Location

                      </div> */}
                      <div className=''>
                        <i className='fa-solid fa-envelope mx-2'></i>
                        planetmusicktm@gmail.com

                      </div>
                      <div className=''>
                        <i className='fa-solid fa-phone mx-2'></i>
                        <a className='text-decoration-none text-light' target='_blank' href="tel:+9779801053815">+977 9801053815</a>

                      </div>

                </div>
            </div>

            
        </div>
        </div>
    </div>
  )
}
