import React, { useState,useEffect, useRef } from 'react'
import { useParams,useNavigate } from 'react-router-dom'

import ItemCard from './ItemCard'
import { apiurl } from './apiurl'
import Footer from './Footer'


// /category
export default function CategoryStore(props) {
  const cat = useParams().category

  const productPerPage = 12

  const handlepageclick = (newCount) =>{
    if (newCount > 0 && newCount <=Math.ceil(categoryProducts.totalProductCount / productPerPage) ){
      setPageCount(newCount)
    }
  }
  
  const [categoryName,setCategoryName] = useState(null)
  const [categoryProducts,setCategoryProducts] = useState(null)
  const [pageCount,setPageCount] = useState(1)

  const thefetch = (payload)=>{
    fetch(`${apiurl}/categories/getcategoryproduct`,{
      method:"POST",
      body:JSON.stringify(payload),
      headers : {"Content-Type":"application/json"}

    })
    .then(res=>res.json())
    .then(data=>{
      setCategoryProducts(data)
    })
    .catch(err=>console.log(err))
  }


  useEffect(() => {
    fetch(`${apiurl}/categories`,{
      method:"GET",
      headers : {"Content-Type":"application/json"}
    })
    .then(res=>res.json())
    .then(data=>{
      const result = data.data.reduce((acc, item) => {
        if (item._id === cat) {
          acc.push(item.category_name);
        } else {
          const subcategory = item.subcategories.find(sub => sub._id === cat);
          if (subcategory) {
            acc.push(subcategory.subcategory_name);
          }
        }
        return acc;
      }, []);
      setCategoryName(result[0])
    })
    .catch(err=>console.log(err))

      const payload = {"category_ref":cat,productPerPage:productPerPage,pageNumber: pageCount}
      thefetch(payload)
  }, [cat,pageCount])




  return (
    <>
    <div className='container'>
      <div className='row row-cols-2 row-cols-md-4'>
        <div className='col'>

        </div>
      </div>
        <h1 className='heading-font inunderline mb-4'>{categoryName}</h1>
        {/* <div className='row'> */}
        <div className='row row-cols-lg-6 row-cols-md-4 row-cols-2'>
        {categoryProducts && categoryProducts.data.map(elem=>(
            <div className='col mb-3'>
          <ItemCard img={elem} />
          </div>
        ))}
        </div>
    </div>

          {/* pagination */}
          <div className='text-center m-3'>
              <div className='btn btn-outline-secondary' onClick={()=>handlepageclick(pageCount-1)}>-</div>&nbsp;
              showing page {pageCount} of {categoryProducts && Math.ceil(categoryProducts.totalProductCount / productPerPage)} 
              &nbsp;<div className="btn btn-outline-secondary" onClick={()=>handlepageclick(pageCount+1)}>+</div>

            </div>

    <Footer />
    </>
  )
}
