import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import authfetch from './authfetch'
import { apiurl, staticurl } from './apiurl'
import ItemCard from './ItemCard'
import Footer from './Footer'
import { CategoryWithSubCategorySelect } from './subcomponents/Selectors'

export default function IndividualBrandDesc() {
  const brandName = useParams().brandname
  const [brand,setBrand] = useState(null)
  const [brandProduct,setBrandProduct] = useState(null)
  const [fetchedBrandproduct,setfetchedBrandproduct] = useState(null)
  const [pageCount,setPageCount] = useState(1)
  const navigate = useNavigate()
  const [brandRelatedDistinctCategory,setBrandRelatedDistinctCategory] = useState(null)
  // const [filterByCategory,setfilterByCategory] = useState(false)
  const [selectedBrandCategory,setSelectedBrandCategory] = useState(null)
  const productPerPage = 12
  const handlepageclick = (newCount) =>{
    // alert("page clicked")
    if (newCount > 0 && newCount <=Math.ceil(brandProduct.totalProductCount / productPerPage) ){
      setPageCount(newCount)

    }
  }
  
  useEffect(() => {
    authfetch(`${apiurl}/brands/get/${brandName}`)
    .then(res=>res.json())
    .then(data=>{
      // console.log(data)
      setBrand(data.data)
    })
    .catch(err=>console.log(err))
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    // alert("page or brand is changed so i am running")
    if (brand) {
      // get brand categories
      authfetch(apiurl+"/search/getdistinctcategory",navigate,{
        method:"POST",
        body: JSON.stringify({brand_ref:brand._id})
      }).then(res=>res.json())
      .then(data=>{
        setBrandRelatedDistinctCategory(data)
      })
      .catch(err=>{
        console.log(err)
      })
      
      // selectedBrandCategory => M647b09e515a794e98c03ea61      
        authfetch(`${apiurl}/brands/getbrandproduct`, navigate, {
          method: "POST",
          body: JSON.stringify({
            _id: brand._id,
            pageNumber:pageCount,
            productPerPage,
            category_ref:selectedBrandCategory,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
  
            setfetchedBrandproduct(data)
          })
          .catch((err) => console.log(err));

      
      
      
    }
    // eslint-disable-next-line
  }, [brand,pageCount,selectedBrandCategory]);

  useEffect(() => {
    setBrandProduct(fetchedBrandproduct)
  }, [fetchedBrandproduct])

  const handleDistinctCategoryChange = (e) =>{
    // set catagory to send through /brands/getbrandproduct
    const val = document.querySelector("#invididualbrand-categorySelect").value
    setSelectedBrandCategory(val)
    setPageCount(1)
    
  }

  return (
    <>
    <div className="container">
        {brand &&
          <>
          <h1>{brand.brandName}</h1>
          <div className='container'>
            <div className='eachbrand'>
              <div className='brandLogo'>
                <img src={staticurl +  brand.brandLogo} className='img-thumbnail' alt={brand.brandName} />
              </div>
              <div className='brandDesc'>
                {brand.brandDesc}
              </div>
              

            </div>
            <div className='clearfix'></div>
            <hr />
          </div>
          
          <div className='prodlists'>
            <h5>Explore the product from {brand.brandName}</h5>
            <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1 my-3'>
              <div className='col'>
                <CategoryWithSubCategorySelect firstOption="All Categories" selectId="invididualbrand-categorySelect" categories={brandRelatedDistinctCategory} onChange={handleDistinctCategoryChange} />
              {/* <select className="form-select" aria-label="select" onChange={(e)=>handleDistinctCategoryChange(e)}>
                <option selected disabled>filter by Category</option>
                {brandRelatedDistinctCategory && brandRelatedDistinctCategory.map(elem=>(
                  <>
                    <option value={elem._id} key={elem._id}>{elem.category_name}</option>
                    {elem.subcategories && elem.subcategories.map(sub=>(
                      <option value={sub._id}>&nbsp;&nbsp;&nbsp;{sub.subcategory_name}</option>
                    ))}

                  </>

                ))}
                
              </select> */}
              </div>
            </div>
            <div className='row row-cols-lg-6 row-cols-md-4 row-cols-2'>
              {brandProduct && brandProduct.data.map(elem=>(
                <div className='col mb-4'>
                  <ItemCard img={elem}/>

                </div>
              ))}
            </div>
            
            <div className='text-center m-3'>
              <div className='btn btn-outline-secondary' onClick={()=>handlepageclick(pageCount-1)}>-</div>&nbsp;
              showing page {pageCount} of {brandProduct && Math.ceil(brandProduct.totalProductCount / productPerPage)} 
              &nbsp;<div className="btn btn-outline-secondary" onClick={()=>handlepageclick(pageCount+1)}>+</div> 

            </div>
          
          </div>

          </>
        }


    </div>
    <Footer />
    </>
  )
}
