import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const telephone = (num) => {
  return (
    <>
      <a href={`tel:${num}`}>{num}</a>
    </>
  );
};

const locationBtn = (url) => {
  return (
    <>
      <a href={url} target="_blank">
        <button className="btn btn-outline-danger">View Location</button>
      </a>
    </>
  );
};

const locationBox = (location, sublocation, numbers, mapUrl) => {
  return (
    <div className="col my-2">
      <div className="card box">
        <div className="locbox text-center">
          <h1>
            <i className="fa-solid fa-location-dot"></i>
          </h1>
          <div className="" style={{ color: "#000" }}>
            <div>{location}</div>
            <div>({sublocation})</div>
            {/* <span>{telephone("014799242")}, {telephone("9803389427")}, {telephone("9865689")}</span> */}
            {numbers.map((number, index) => (
              <React.Fragment key={index}>
                {telephone(number)}
                {index !== numbers.length - 1 && ", "}
              </React.Fragment>
            ))}
            <div className="mt-2">{locationBtn(mapUrl)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Contact() {
  return (
    <>
      <div className="container">
        <h3 className="inunderline heading-font">Contact us</h3>
        <h4 className="text-center heading-font">Our Branches</h4>
        <div className=" justify-content-center">
          <div className="px-auto">
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 my-2 justify-content-center">
              {locationBox(
                "Pokhara",
                "Aalu Maila chowk",
                ["9843527353"],
                "https://goo.gl/maps/pGkNXnx84QLmw85e6"
              )}
              {locationBox(
                "String Buzz Nepal (Madhyapur Thimi)",
                "Opposite of Madhyapur Hospital",
                ["9843527353"],
                "https://maps.app.goo.gl/ELYdMZXU7LVaLkKM8"
              )}
            </div>
          </div>
        </div>
        <div className="map mt-4">
          <iframe
            title="Planet Music Nepal Location"
            src="https://www.google.com/maps/d/u/0/embed?mid=1YnLPdqtkQdje5IFAUgTmsQ3xXwXMsDI&ehbc=2E312F"
            style={{ width: "100%", height: "500px" }}
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
}
