import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { apiurl } from './apiurl'
import Alert from './Alert'
import { useEffect } from 'react'
// import authfetch from './authfetch'

export default function AdminLogin(props) {

    useEffect(() => {
      if (localStorage.getItem('token')){
        fetch(`${apiurl}/verifylogin`,{
          method:"GET",
          headers:{"Authorization":localStorage.getItem('token')}
        }).then(res=>{
          if (res.status === 200){
            window.location.href = "/adminpanel"
          }
        })
        .catch(err=>{
          console.log(err)
          console.log(err.message)
          alert("error while logiing in check console for error message")
          localStorage.removeItem("token")
        })
      }

    }, [])
    
    
    const [invalidCounter, setinvalidCounter] = useState(0)
    // const navigate = useNavigate()
    const handleLogin = (e) =>{
        e.preventDefault()
        const form = document.querySelector(".adminloginForm")
        if (!form.checkValidity()){
            form.reportValidity()
            return
        }
        const username = document.querySelector("#username").value
        const password = document.querySelector("#password").value
        console.log(username,password)
        const headers = {
            "Content-Type": "application/json"
        }
        fetch(`${apiurl}/login`,{
            method:"POST",
            body:JSON.stringify({username,password}),
            headers:headers
        }).then(res=>res.json())
        .then(data=>{
            if (data.token){
                localStorage.setItem("token","Bearer "+data.token)
                window.location.href = "/adminpanel"
            }else{
                // console.log()
                setinvalidCounter(invalidCounter+1)
                document.querySelector(".errmsg").innerHTML = Alert(data.message,"danger")
            }
        })
        .catch(err=>{
            console.log(err)
        })

    }
  return (
    <>
    <div className='container'>
{/* <!-- Form--> */}
<div className="form">
  <div className="form-panel one">
    <div className="form-header">
      <h1>Admin Login</h1>
    </div>
    <div className="form-content">
      <form className='adminloginForm'>
        <div className="form-group">
          <label for="username">Username</label>
          <input id="username" type="text" name="username" required="required"/>
        </div>
        <div className="form-group">
          <label for="password">Password</label>
          <input id="password" type="password" name="password" required="required"/>
        </div>
        <div className='errmsg'></div>
        <div className="form-group mt-5">
          <button type="submit" onClick={(e)=>handleLogin(e)}>Log In</button>
        </div>
      </form>
    </div>
  </div>

</div>
</div>
    </>
  )
}
