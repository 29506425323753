
import { React,useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiurl } from './apiurl';
import ItemCard from './ItemCard';
import Footer from './Footer';


export default function Search() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery()
    const [searchData, setSearchData] = useState(null)
    const [pageCount,setPageCount] = useState(1)

    const productPerPage = 12;


    const handlepageclick = (newCount) =>{
        if (newCount > 0 && newCount <=Math.ceil(searchData.totalProductCount / productPerPage) ){
          setPageCount(newCount)
        }
      }

    useEffect(()=>{
        fetch(apiurl+"/search",{
            method:"POST",
            body: JSON.stringify({
                searchQuery:query.get('q'),
                categoryType:query.get('categorytype'), //["Main","Sub","All"]
                categoryType_ref:query.get('ref'),
                productPerPage:productPerPage,
                pageNumber:pageCount,
                latest:true
            }),
            headers:{
                "Content-Type":"application/json"
            }
        }).then(res=>res.json())
        .then(data=>{
            setSearchData(data)
        })

    },[pageCount,query.get('q'),query.get('ref'),query.get('categorytype')])

    useEffect(()=>{
        setPageCount(1)
    },[query.get('q'),query.get('ref'),query.get('categorytype')])


  return (
    <>
    <div className="container mt-3" style={{"minHeight":"50vh"}}>
        {/* <h3>Search Result for: {query.get('q')}</h3> */}
        <h3 className='heading-font'>Search Result:</h3>

        <div className='row row-cols-lg-6 row-cols-md-4 row-cols-2'>
            {searchData && (searchData.data.length === 0)?"No Product Found":""}
            
                {searchData && searchData.data.map((elem) => (
                    <div className='col mb-4' key={elem._id}>
                        <ItemCard img={elem} />
                    </div>
                ))}

           
            
        </div>

        <div className='text-center m-3'>
              <div className='btn btn-outline-secondary' onClick={()=>handlepageclick(pageCount-1)}> - </div>&nbsp;
              showing page {pageCount} of {searchData && Math.ceil(searchData.totalProductCount / productPerPage)} &nbsp;
              <div className="btn btn-outline-secondary" onClick={()=>handlepageclick(pageCount+1)}> + </div>

            </div>


        
    </div>
    <Footer />
    </>
  )
}
