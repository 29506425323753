import React,{useState,useEffect} from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import {apiurl,staticurl} from './apiurl'
import { useNavigate } from 'react-router-dom'
import authfetch from './authfetch'
import ItemCard from './ItemCard'
import Loading from './subcomponents/Loading'


export default function Home() {
  const [brands, setBrands] = useState(null)
  const [latestProducts,setLatestProducts] = useState(null)
  const [carouselData,setCarouselData] = useState(null)

  // document.querySelector(".loading").style.display= ""

  const navigate = useNavigate()

  useEffect(() => {
    authfetch(apiurl+"/brands",navigate).then(resp=>resp.json())
    .then(data=>setBrands(data.data))
    .catch(err=>console.log(err))

    authfetch(apiurl+"/search",navigate,{
      method:"POST",
      body: JSON.stringify({
          productPerPage:6,
          latest:true
      })
    })
    .then(res=>res.json())
    .then(data=>{
      // console.log("latest product data",data)
      setLatestProducts(data.data)
      document.querySelector(".loading").style.display = "none"
    
    })
    .catch(err=>{
      console.log(err)
    })

    // get data for carousel images slider
    fetch(apiurl+"/sliderimages").then(res=>res.json())
    .then(data=>{
      // console.log(data)
      setCarouselData(data.data)

    }).catch(err=>{
      console.log(err)
    })

    // eslint-disable-next-line
  }, [])

  window.onload = () =>{
    /*global bootstrap */
    
    fetch(apiurl+"/notice").then(res=>res.json())
    .then(data=>{
      if (data.show_image){
        document.querySelector("#homeNoticeImage").src = staticurl + data.image
        const modal = new bootstrap.Modal("#noticeModal")
        modal.show()
      }
    })
    .catch(err=>{
      console.log(err)
    })
  }

  return (
    <>
    <Loading />

{/* <!-- home popup --> */}
<div className="modal fade homepopup" id="noticeModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Notice</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <img src='#' className='img-fluid' id="homeNoticeImage" alt="notice"></img>
      </div>
    </div>
  </div>
</div>

    <div className='container'>
    


<div className='border mt-0'>
<h2 className='mt-1 heading-font'>Our Authorized Dealers <Link to="/brands" style={{fontSize:"14px"}}>|Show All</Link> </h2>
<div className='brandLogoWrapper'>
  <div className='cont'>
    {brands && brands.filter(elem=>!elem.ownBrand).map(brand=>(
      <Link to={`/brands/${brand.brandName}`} key={brand._id}>
        <img loading='lazy' src={staticurl+brand.brandLogo.replace("/brandlogos/","/thumbnails/")} height="120px" className='mx-2 c1 radius50' alt={brand.brandName} />
      </Link>
    ))}
  </div>
</div>

</div>
<div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-indicators">
    {carouselData && carouselData.map((elem,index)=>(
      // <div className={`carousel-item ${(index==0)? "active":""}`}>
      //   <img src={staticurl+  elem.image_path} className="d-block w-100" alt={elem.image_label}/>
      // </div>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={(index===0?"active":"")} aria-current={index===0?"true":""} aria-label="Slide 1" key={index}></button>

    ))}
    {/* <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
  </div>
  <div className="carousel-inner mt-2">
    {carouselData && carouselData.map((elem,index)=>(
      <a href={elem.image_linkto} target='_blank' rel='noreferrer' key={index}>
        <div className={`carousel-item ${(index===0)? "active":""}`}>
          <img src={staticurl+  elem.image_path} className="d-block w-100 carousel-image" alt={elem.image_label}/>
        </div>
      </a>
    ))}
  </div>
  <button className="carousel-control-prev" type="button btn-primary" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
    <span className="carousel-custom-icon" aria-hidden="true"><i className="fa-solid fa-angle-left"></i></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
    <span className="carousel-custom-icon" aria-hidden="true"><i className="fa-solid fa-angle-right"></i></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


<div className='border m-2'>
<h2 className='mt-3 heading-font'>Our Own Brands </h2>
<div className='brandLogoWrapper'>
  <div className='cont'>
    {brands && brands.filter(elem=>elem.ownBrand).map(brand=>(
      <Link to={`/brands/${brand.brandName}`} key={brand._id}>
        <img loading='lazy' src={staticurl+brand.brandLogo.replace("/brandlogos/","/thumbnails/")} height="120px" className='mx-1 c1 radius50' alt={brand.brandName} />
      </Link>
    ))}
  </div>
</div>
</div>


<div className='border m-2'>
<h2 className='mt-3 heading-font'>Latest products</h2>
      <div className='row row-cols-lg-6 row-cols-md-4 row-cols-2'>
      {latestProducts && latestProducts.map(product=>(
        <div className='col mb-4' key={product._id}>
        <ItemCard img={product}/>

        </div>
      ))}
      </div>
</div>
      

    </div>
    <Footer />

  </>
  )
}
