import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import authfetch from './authfetch'
import { apiurl } from './apiurl'
import { Link, useNavigate } from 'react-router-dom'

export default function Brands() {
    const [brands, setBrands] = useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        authfetch(`${apiurl}/brands`,navigate).then(resp=>resp.json())
        .then(data=>{
            const brands = data.data
            const groupedBrands = brands.reduce((acc,brand)=>{
                const firstLetter = brand.brandName[0].toUpperCase()
                if (!acc[firstLetter]){
                    acc[firstLetter] = []
                }
                acc[firstLetter].push(brand)
                return acc
            },{});
            // console.log(groupedBrands)

            // setBrands(data.data)
            setBrands(groupedBrands)
        })
        .catch(err=>console.log(err))
    }, [])
    // const letters = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("")

    // const [brandsByletter,setBrandsByletter] = useState(null)

  return (
    <>
    <div className='container'>
        <div className=''>

        {/* {brands && brands.map(elem=>(
            <div className='col' key={elem._id}>
                <div className='card h-100' style={{height:"auto","width":"auto"}}>
                    <img src={staticurl + elem.brandLogo} alt={elem.brandName}></img>
                    <span className='text-center'>{elem.brandName}</span>
                </div>
            </div>
        ))} */}

<h4 className='inunderline my-3 heading-font'>Available Brands</h4>
{ 
    brands && Object.keys(brands).map(letter => {
        return (
            <div className='' key={letter}>
                <h5 className='underline'>{letter}</h5>
                <div className='row row-cols-2 row-cols-md-4 row-cols-lg-6'>
                {brands[letter].map(elem => (
                    <div key={elem._id}>
                        <Link to={`/brands/${elem.brandName}`} className='list-brand-name'>
                        {elem.brandName}
                        </Link>
                    </div>
                ))}
                </div>

                <hr style={{float:"clear"}} />
            </div>
        );
    })
}
        </div>



    </div>
    <Footer />
    </>
  )
}
