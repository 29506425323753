import React from "react";

const BrandSelect = (props) => {
    const handleBrandChange = (event) => {
      const selectedValue = event.target.value;
      // Call the onChange prop function if provided
      if (props.onChange) {
        props.onChange(selectedValue);
      }
    };
    return (
      <select className="form-select brandSelect" aria-label="Brands" id={props.brandId} required onChange={handleBrandChange}>
        <option selected disabled>Select Brand</option>
        {props.brands && props.brands.map(elem=>(
          <option value={elem._id} key={elem._id}>{elem.brandName}</option>
        ))}
      </select>
    )
}



const CategorySelect = (props) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    // Call the onChange prop function if provided
    if (props.onChange) {
      props.onChange(selectedValue);
    }
  };
  return (
    <select className="form-select categorySelect" id={props.selectId} aria-label="Category" required onChange={handleSelectChange}>
      <option selected disabled>Select Category</option>
      {props.categories && props.categories.map(elem=>(
        <option value={elem._id} key={elem._id}>{elem.category_name.length > 20? elem.category_name.substring(0,24)+"...":elem.category_name}</option>
      ))}
    </select>
  )
}

const SubCategorySelect = (props) => {
  return (
    <select className="form-select categorySelect" id={props.selectId} aria-label="subcategory">
      <option selected disabled>Select Subcategory(if any)</option>
      {props.subcategories && props.subcategories.map(elem=>(
        <option value={elem._id} key={elem._id}>{elem.subcategory_name}</option>
      ))
        
      }
    </select>
  )
}

/**
 * 
 * @param {} props
 * props = {selectId,handleSelectChange,utils,categories}
 * utils is a dict with {isRequired:true,disabledFirst:false}
 * @returns Category with subcategories <select> component
 */
const CategoryWithSubCategorySelect = (props) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    // Call the onChange prop function if provided
    if (props.onChange) {
      props.onChange(selectedValue);
    }
  };

  // M and S represent Main or Sub category
  return (
    <select className="form-select cat-subcat-select" id={props.selectId} onChange={handleSelectChange} required={props.isRequired}>
      <option selected disabled={props.disableFirstOption}>{props.firstOption}</option>
      {props.categories && props.categories.map(elem=>(
        <>
        <option className="level-0" value={`M${elem._id}`} key={elem._id}>{elem.category_name.length > 20? elem.category_name.substring(0,24)+"...":elem.category_name}</option>
        {elem.subcategories && elem.subcategories.map(subcat=>(
          <option className="level-1" value={`S${subcat._id}`} disabled={props.disableSubCategory}>&nbsp;&nbsp;&nbsp;{subcat.subcategory_name}</option>
        ))}        
        </>
      ))}
    </select>
  )
}


export {BrandSelect, CategorySelect,CategoryWithSubCategorySelect,SubCategorySelect}
  