import React,{useState,useEffect} from 'react'
import authfetch from './authfetch'
import { apiurl, staticurl } from './apiurl'
import { useNavigate } from 'react-router-dom'
import Alert from './Alert'
import axios from 'axios'
import UploadProgressBar from './subcomponents/UploadProgressBar'
import {BrandSelect,CategoryWithSubCategorySelect} from "./subcomponents/Selectors"




const EditCategory = (props) => {
  const handleCategoryChange = (selectedValue) => {
    // Perform actions specific to this place
    const newCategoryname = document.querySelector(".newCategoryname")
    const result = props.categories.reduce((acc, item) => {
      if (item._id === selectedValue.slice(1,)) {
        acc.push(item.category_name);
      } else {
        const subcategory = item.subcategories.find(sub => sub._id === selectedValue.slice(1,));
        if (subcategory) {
          acc.push(subcategory.subcategory_name);
        }
      }
      return acc;
    }, []);
    // alert(result[0])
    // newCategoryname.value = props.categories.filter(elem=>elem._id === selectedValue)[0].category_name
    newCategoryname.value = result[0]
    
  };

  const handleEditCategory = () =>{
    const form = document.querySelector('.editCategoryForm')
    let selectedCategory = document.querySelector("#editCategorySelect").value
    if (selectedCategory === "Select Category/SubCategory"){
      alert("Select Category/SubCategory first and edit to continue")
    }
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }
    
    const editedCategory = document.querySelector(".newCategoryname").value
    // alert(props.categories.filter(elem=>elem._id === selectedCategory)[0].category_name)
    // rename category request
    authfetch(apiurl+"/categories",props.navigate,{
      method:"PUT",
      body: JSON.stringify({_id:selectedCategory,category_name:editedCategory})
    }).then(res=>res.json())
    .then(data=>{
      console.log(data)
      if (data.status === "ok"){
        alert(data.message)
        props.setUpdates(props.updates.categoryUpdates + 1)
      }else{
        alert("something wrong.."+data.message)
      }
      form.reset()
    })

  }

  const handleDeleteCategory = () =>{
    // const form = document.querySelector('.editCategoryForm')
    let selectedCategory = document.querySelector("#editCategorySelect").value

    const result = props.categories.reduce((acc, item) => {
      if (item._id === selectedCategory.slice(1,)) {
        acc.push(item.category_name);
      } else {
        const subcategory = item.subcategories.find(sub => sub._id === selectedCategory.slice(1,));
        if (subcategory) {
          acc.push(subcategory.subcategory_name);
        }
      }
      return acc;
    }, []);

    // const catName = props.categories.filter(elem=>elem._id === selectedCategory)[0].category_name
    const catName = result[0]
    const answer = prompt(`Deleting  ${catName} will delete all product associated with ${catName}.Are you sure you want to delete category '${catName}'?(yes/no)`)
    if (answer !== "yes") return
    authfetch(apiurl+"/categories",props.navigate,{
      method:"DELETE",
      body: JSON.stringify({_id:selectedCategory})
    }).then(res=>{
      if (res.status === 204){
        alert("deleted "+catName)
        props.setUpdates(props.updates.categoryUpdates - 1)
      }
    })
    .then(data=>console.log(data))
    .catch(err=>console.log(err))

  }

  return (
    <>

        {/* <!-- Modal --> */}
      <div className="modal fade" id="editCategory" tabIndex="-1" aria-labelledby="editCategoryLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editCategoryLabel">Edit Category</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='container'>
                <form className='editCategoryForm'>
                <CategoryWithSubCategorySelect
                categories={props.categories}
                onChange={handleCategoryChange}
                selectId="editCategorySelect"
                firstOption="Select Category/SubCategory"
                 />
                <div className="input-group my-3">
                  <span className="input-group-text" id="basic-addon1">NewName</span>
                  <input type="text" className="form-control newCategoryname" placeholder="New Category Name" aria-label="newcatname" aria-describedby="basic-addon10" required/>
                </div>
                </form>
                
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={handleDeleteCategory}><i className="fa-solid fa-trash-can"></i> Delete</button>
              <button type="button" className="btn btn-success" onClick={handleEditCategory}><i className="fa-solid fa-floppy-disk"></i> Edit changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


// brand edit garna lai model
const EditBrand = (props) =>{
  const handleBrandChange = (selectedValue) => {
    console.log(props.brands)
    const newBrandname = document.querySelector(".newBrandnameEdit")
    const selectedBrandDict = props.brands.filter(elem=>elem._id === selectedValue)[0]
    newBrandname.value = selectedBrandDict.brandName
    document.querySelector(".newbrandDescEdit").value = selectedBrandDict.brandDesc
    const image = document.querySelector(".editBrandImage")
    image.src = staticurl + selectedBrandDict.brandLogo
  };

  // validates input and send request to /brands :PUT
  const handleSaveEditBrandBtnClick = () =>{
    const form = document.querySelector('.editBrandForm')
    let selectedBrand = document.querySelector("#editBrandSelect")
    if (selectedBrand.value === "Select Brand"){
      document.querySelector("#editBrandSelect").value = ""
    }
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }


    // console.log(selectedBrand)
    const brandName = document.querySelector(".newBrandnameEdit").value.trim()
    const brandDesc = document.querySelector(".newbrandDescEdit").value.trim()
    // selectedBrand = document.querySelector("#editBrandSelect")
    let brandLogo  = props.brands.filter(elem=>elem._id === selectedBrand.value)[0].brandLogo


    // console.log(brandName,brandDesc,brandLogo)
    const formData = new FormData()
    formData.append("_id",selectedBrand.value)
    formData.append("brandName",brandName)
    formData.append("brandDesc",brandDesc)
    formData.append("brandLogo",brandLogo)

    // check if newbrandlogo was added
    const newlogo = document.querySelector("#editBrandImage").files
    if (newlogo.length > 0){
      // found new logo
      formData.append("newLogo",newlogo[0]) 
    }


    // // rename brand request
    const confirm = window.confirm("Are you sure you want to save changes?")
    if (!confirm) return
    fetch(apiurl+"/brands",{
      method:"PUT",
      body: formData,
      headers:{"Authorization":localStorage.getItem('token')}
    }).then(res=>res.json())
    .then(data=>{
      console.log(data)
      if (data.status === "ok"){
        alert(data.message)
        props.setUpdates(props.updates.brandUpdates + 1)
      }else{
        alert("something wrong.."+data.message)
        console.log("this is data",data)
      }
      form.reset()
    }).catch(err=>console.log(err))
  }

  const handleDeleteBrand = () =>{
    // const form = document.querySelector('.editCategoryForm')
    let selectedBrand = document.querySelector("#editBrandSelect").value
    if (selectedBrand === "Select Brand"){
      alert("first select brand to continue")
      return
    }

    const brandName = props.brands.filter(elem=>elem._id === selectedBrand)[0].brandName

    const answer = prompt(`Deleting  ${brandName} will delete all product associated with ${brandName}.Are you sure you want to delete category '${brandName}'?(yes/no)`)
    if (answer !== "yes") return
    authfetch(apiurl+"/brands",props.navigate,{
      method:"DELETE",
      body: JSON.stringify({_id:selectedBrand})
    }).then(res=>{
      if (res.status === 204){
        alert("deleted "+brandName)
        props.setUpdates(props.updates.brandUpdates - 1)
      }
    })
    .then(data=>console.log(data))
    .catch(err=>console.log(err))

  }


  return  (
    <>
      <div className="modal fade" id="editBrandModel" tabIndex="-1" aria-labelledby="editBrandLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editCategoryLabel">Edit Brand</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='container'>
                <form className='editBrandForm'>
                <BrandSelect brands={props.brands} onChange={handleBrandChange} brandId="editBrandSelect" />
                <div className="input-group my-3">
                  <span className="input-group-text" id="basic-addon1">Name</span>
                  <input type="text" className="form-control newBrandnameEdit" placeholder="Renamed Brand Name" aria-label="newbrandname" aria-describedby="basic-addon10" required/>
                </div>
                <div className="input-group">
                  <span className="input-group-text">Desc&nbsp;</span>
                  <textarea required className="form-control newbrandDescEdit" placeholder='Brand Description' aria-label="Brand Description" style={{height:"150px"}}></textarea>
                </div>
                <div className='brand_image-container mt-3'>
                  Current Logo<br />
                  <img src='#' className='img-fluid editBrandImage' style={{height:"75px",border:"2px solid #e2e2e2"}} alt=""></img>
                </div>
                <div className="my-3" style={{border:"2px solid #e2e2e2"}}>
                  <span>use only if you want to change brand Logo</span>
                  <input className="form-control" type="file" accept="image/*" id="editBrandImage"  />
                  <div className='showImageNames my-2'></div>
                </div>

                </form>
                
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" id="deleteBrandBtn" className="btn btn-danger"  onClick={handleDeleteBrand}><i className="fa-solid fa-trash-can"></i> Delete</button>
              <button type="button" id="editBrandBtn" className="btn btn-success"  onClick={handleSaveEditBrandBtnClick}><i className="fa-solid fa-floppy-disk"></i> Edit changes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



// main componet of adminpanel
export default function AdminPanel() {
  const navigate = useNavigate()
  // state variables
  const [brands, setBrands] = useState(null)
  const [categories, setCategories] = useState(null)
  // const [subcategories, setSubCategories] = useState(null)
  const [uploadProgress,setUploadProgess] = useState(0)
  const [updates,setUpdates] = useState({"brandUpdates":0,"categoryUpdates":0})


  const handleProdImageChange = () =>{
    const images = document.querySelector(".prod_img").files
    console.log(images)
    const names = []
    const imagePreviewContainer = document.querySelector(".showImageNames")
    imagePreviewContainer.innerHTML = "<span>Select Thumbnail<span>"
    var reader = new FileReader();

    for (let i=0;i<images.length;i++){
      names.push(images[i].name)
      // eslint-disable-next-line
      var reader = new FileReader();
          var imagePreview = document.createElement('img');
          imagePreview.className = 'prod-image-preview';

          reader.onload = (function(image) {
            return function(e) {
              image.src = e.target.result;
              const inputRadioDiv = document.createElement("div")
              const inputRadio = document.createElement("input")
              inputRadio.type = "radio"
              inputRadio.value = images[i].name
              inputRadio.name = "product_thumbnail"
              inputRadio.required = true

              inputRadioDiv.appendChild(inputRadio)
              inputRadioDiv.appendChild(image)

              imagePreviewContainer.appendChild(inputRadioDiv);
            };
          })(imagePreview);

          reader.readAsDataURL(images[i]);
    }
  }

  const handleAddProduct = (e) =>{
    e.preventDefault()
    const form = document.querySelector(".addProductForm")
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }
    let selectedBrand = document.querySelector("#addBrandSelect").value
    if (selectedBrand === "Select Brand"){
      alert("first select brand to continue")
      return
    }
    let selectedCategory = document.querySelector("#addCategorySelect").value
    if (selectedCategory === "Select Category/SubCategory"){
      alert("first select Category/SubCategory to continue")
      return
    }
    const prod_name = document.querySelector(".prod_name").value
    const prod_desc = document.querySelector(".prod_desc").value
    // let specsElement = document.querySelector(".speclist").children
    const brand_ref = document.querySelector(".brandSelect").value
    const category_ref = document.querySelector("#addCategorySelect").value
    const prod_img = document.querySelector(".prod_img")
    const product_thumbnails = document.querySelectorAll('input[name="product_thumbnail"]')
    let product_thumbnail;
    product_thumbnails.forEach(input=>{
      if (input.checked){
        product_thumbnail = input.value
      }
    })

    const formData = new FormData()
    formData.append("prod_name",prod_name)
    formData.append("prod_desc",prod_desc)
    formData.append("brand_ref",brand_ref)
    formData.append("category_ref",category_ref)
    formData.append("product_thumbnail",product_thumbnail)
    // Append each file individually
    for (let i = 0; i < prod_img.files.length; i++) {
      formData.append("prod_img", prod_img.files[i]);
    }
    const headers = {"Authorization":localStorage.getItem("token")}

    document.querySelector("#progressAddProduct").style.display = ""; //removes display:none from progressbar on file upload

    // axios: ok
    axios.request({
      method: "post", 
      url: `${apiurl}/products`, 
      data: formData, 
      headers:headers,
      onUploadProgress: (event) => {
        const percent = (event.loaded / event.total);
        setUploadProgess(percent.toFixed(2));
      }
    }).then (data => {
      console.log(data)
      document.querySelector(".alertbox").innerHTML = Alert(data.data.message,"success")
      document.querySelector("#progressAddProduct").style.display = "none";
      form.reset()
    })
    .catch(err=>{
      console.log("error occured: ",err)
    })    

    // fetch api : working
    // fetch(`${apiurl}/products`,{
    //   method:"POST",
    //   body:formData,
    //   headers:headers,
    //   onUploadProgress: (event) => {
    //     console.log(event)
    //     alert("uploading")
    //     if (event.lengthComputable) {
    //       const percent = (event.loaded / event.total) * 100;
    //       setUploadProgess(percent.toFixed(2));
    //       console.log(percent);
    //     }
    //   }
    // }).then(res=>res.json())
    // .then(data=>document.querySelector(".alertbox").innerHTML = Alert(data.message,"success"))
    // .catch(err=>console.log(err))

  }

  const handleAddBrand = (e) =>{
    e.preventDefault()
    const form = document.querySelector(".addBrandForm")
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }
    // data
    let newBrand = document.querySelector(".newBrandInput").value
    newBrand = newBrand.charAt(0).toUpperCase() + newBrand.slice(1);
    const brandDesc = document.querySelector(".newbrandDesc").value
    const ownBrand = document.querySelector("#ownBrand").checked
    const brandImage = document.querySelector(".newBrandLogo").files[0]

    const formData = new FormData()
    formData.append("brandName",newBrand)
    formData.append("brandDesc",brandDesc)
    formData.append("brandImage",brandImage)
    formData.append("ownBrand", ownBrand)
    console.log(formData)

    // authfetch(`${apiurl}/brands`,navigate,{
    //   method:"POST",
    //   body: formData
    // }).then(resp=>resp.json())
    // .then(data=>console.log(data))
    // .catch(err=>console.log("error occured",err))
    const headers = {"Authorization":localStorage.getItem("token")}

    document.querySelector("#progressAddBrand").style.display = "" //show progress bar on upload

    axios.request({
      method: "post", 
      url: `${apiurl}/brands`, 
      data: formData, 
      headers:headers,
      onUploadProgress: (event) => {
        const percent = (event.loaded / event.total);
        setUploadProgess(percent.toFixed(2));
      }
    }).then (data => {
      console.log(data)
      document.querySelector(".alertbox").innerHTML = Alert(data.data.message,"success")
      document.querySelector("#progressAddBrand").style.display = "none"; //hide progress bar after upload
      form.reset()
    })
    .catch(err=>{
      console.log("error occured: ",err)
    })
  }

  const handleAddCategory = (e) =>{
    e.preventDefault()
    const form = document.querySelector(".addCategoryForm")
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }
    // alert("handling")
    // data
    let newCategory = document.querySelector(".newCategoryInput").value.trim()
    newCategory = newCategory.charAt(0).toUpperCase() + newCategory.slice(1);


    authfetch(`${apiurl}/categories`,navigate,{
      method:"POST",
      body: JSON.stringify({"category_name":newCategory})
    }).then(res=>res.json())
    .then(data=>{
      if (data.status === "ok"){
        alert(`[ok] ${data.message}`)
        setUpdates(updates.categoryUpdates + 1)
        form.reset()
      }else{
        alert(`[error] ${data.message}`)
      }
    })
    .catch(err=>console.log(err))
  }

  // handle subcategory creation
  const handleAddSubCategory = (e) =>{
    e.preventDefault()
    const form = document.querySelector(".addSubCategoryForm")
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }

    let newSubCategory = document.querySelector(".newSubCategoryInput").value.trim()
    newSubCategory = newSubCategory.charAt(0).toUpperCase() + newSubCategory.slice(1);
    
    let subcategoryOf = document.querySelector("#addSubcategorySelect").value
    if (subcategoryOf === "Select MainCategory"){
      alert("select MainCategory before creating SubCategory!")
      return
    }

    authfetch(`${apiurl}/categories`,navigate,{
      method:"POST",
      body: JSON.stringify({"subcategory_name":newSubCategory,"category_ref":subcategoryOf.slice(1,)})
    }).then(res=>res.json())
    .then(data=>{
      if (data.status === "ok"){
        alert(`[ok] ${data.message}`)
        setUpdates(updates.categoryUpdates + 1)
        form.reset()
      }else{
        alert(`[error] ${data.message}`)
      }
    })
    .catch(err=>console.log(err))
  }


  
  useEffect(() => {
    // check for valid token
    // authfetch(`${apiurl}/verifylogin`,navigate)
    if (localStorage.getItem('token')){
      fetch(`${apiurl}/verifylogin`,{
        method:"GET",
        headers:{"Authorization":localStorage.getItem('token')}
      }).then(res=>{
        if (res.status === 200){
          console.log("valid login")
        }else{
          localStorage.removeItem("token")
          window.location.href = "/admin"
        }
      })
      .catch(err=>{
        console.log(err.message)
        localStorage.removeItem("token")
        window.location.href = "/admin"
      })      
    }
    else{
      window.location.href = "/admin"
    }

    authfetch(`${apiurl}/brands`,navigate)
    .then(res=>res.json())
    .then(data=>setBrands(data.data))
    .catch(err=>console.log(err))

    authfetch(`${apiurl}/categories`,navigate)
    .then(res=>res.json())
    .then(data=>setCategories(data.data))
    .catch(err=>console.log(err))

    // authfetch(`${apiurl}/categories/subcategories`,navigate)
    // .then(res=>res.json())
    // .then(data=>setSubCategories(data.data))
    // .catch(err=>console.log(err))

  }, [navigate,updates])

  const handleLogout = () =>{
    localStorage.removeItem("token")
    window.location.href = "/admin"
  }

  return (
    <>
    <div className='container mt-3'>
    <div className="accordion" id="accordionExample">
      {/* prod add accordian start */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        Add Product
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <form className='addProductForm'>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">Name</span>
          <input required type="text" className="form-control prod_name" placeholder="Product Name" aria-label="Product Name" aria-describedby="basic-addon1"/>
        </div>
        <div className="input-group">
          <span className="input-group-text">Desc&nbsp;</span>
          <textarea required className="form-control prod_desc" placeholder='Product Description' aria-label="Product Description" style={{height:"150px"}}></textarea>
        </div>

          <div className='row mt-3'>
            <div className='col'>
              <BrandSelect brands={brands} brandId="addBrandSelect"/>
            </div>
            <div className='col'>
              {/* <CategorySelect categories={categories} selectId="addCategorySelect" /> */}
              
              <CategoryWithSubCategorySelect 
              categories={categories} 
              selectId="addCategorySelect"
              isRequired={true}
              firstOption="Select Category/SubCategory"
              disableFirstOption={true}

               />
            </div>
            {/* <div className='col'>
              <SubCategorySelect subcategories={subcategories} selectId="addSubCategorySelect"/>
            </div> */}
          </div>

          <div className="my-3 imageFileDiv">
            <input className="form-control prod_img" type="file" multiple id="formFile" onChange={handleProdImageChange} required/>
            <div className='showImageNames my-2'></div>
          </div>
          
          <UploadProgressBar uploadProgressValue={uploadProgress} progressBarId="progressAddProduct" />

          <button className='btn btn-primary' onClick={(e)=>handleAddProduct(e)}>Add Product</button>

        </form>
      </div>
    </div>
  </div>
  {/* prod add accordian end */}

  {/* brand add acc start */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Add Brand
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <form className='addBrandForm'>
          <div className="input-group mb-3">
          <span className="input-group-text">Name</span>
            <input type="text" required className="form-control newBrandInput" placeholder="New Brand Name" aria-label="new brand" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group">
            <span className="input-group-text">Desc&nbsp;</span>
            <textarea required className="form-control newbrandDesc" placeholder='Brand Description' aria-label="Brand Description" style={{height:"150px"}}></textarea>
          </div>
          <div className="my-3">
            <input className="form-control newBrandLogo" type="file" id="newBrandLogo" required/>
          </div>
          <UploadProgressBar uploadProgressValue={uploadProgress} progressBarId="progressAddBrand" />
          <div className='mb-4'>
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="ownBrand" style={{border: "2px solid #4c4747"}} />
            <label className="form-check-label" htmlFor="ownBrand">Own Brand</label>
          </div>
          {/* <input type='radio' id="ownBrand"/> Own Brand */}
          </div>


          <button className="btn btn-primary" onClick={(e)=>handleAddBrand(e)}>Add Brand</button>
          <button type="button" className="btn btn-success mx-4" data-bs-toggle="modal" data-bs-target="#editBrandModel">
            Edit Brand
          </button>
          <EditBrand  brands={brands} updates={updates} setUpdates={setUpdates} />
          
        </form>
      </div>
    </div>
  </div>

  {/* brand add acc end */}

          {/* category add accordian start */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Add Category
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
          <div className="note"><strong>Note:</strong> <strong>CategoryType</strong> cannot be change once created (<strong>MainCategory</strong> can't be changed to <strong>SubCategory</strong> and vice versa). Create Carefully!</div>
        <form className='addCategoryForm'>
          <div className="input-group mb-3">
          <span className="input-group-text">MainCategory Name</span>
            <input type="text" required className="form-control newCategoryInput" placeholder="New Category Name" aria-label="new category" aria-describedby="basic-addon1" />
          </div>
          <button className="btn btn-primary" onClick={(e)=>handleAddCategory(e)}>Add Category</button>
          <button type="button" className="btn btn-success mx-4" data-bs-toggle="modal" data-bs-target="#editCategory">
          Edit Category/SubCategory
          </button>
        </form>
        <hr className='my-4' />

        <form className='addSubCategoryForm mt-3'>
          <div className="input-group mb-3">
          <span className="input-group-text">SubCategory Name</span>
            <input type="text" required className="form-control newSubCategoryInput" placeholder="New SubCategory Name" aria-label="new subcategory" aria-describedby="basic-addon1" />
          </div>
          <div className="input-group mb-3">
          <span className="input-group-text">SubCategory Of</span>
          <CategoryWithSubCategorySelect 
          categories={categories}
          firstOption="Select MainCategory"
          disableFirstOption={true}
          disableSubCategory={true}
          isRequired={true}
          selectId="addSubcategorySelect"
           />
            
          </div>
          <button className="btn btn-primary" onClick={(e)=>handleAddSubCategory(e)}>Add SubCategory</button>

        </form>
        
        <EditCategory categories={categories} updates={updates} setUpdates={setUpdates}/>


      </div>
    </div>
  </div>
  {/* category add accoridan end */}



        {/* home notice start */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        Home Notice
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">      
        <Notice uploadProgress={uploadProgress} setUploadProgess={setUploadProgess}/>
      </div>
    </div>
  </div>
  {/* home notice ends */}

  {/* Add Slider Image component */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        Slider Images
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">      
        <Slider uploadProgress={uploadProgress} setUploadProgess={setUploadProgess}/>
      </div>
    </div>
  </div>


  {/* Change password accoridan start */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseFive">
        Change Password
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">      
        <ChangePassword navigate={navigate} />
      </div>
    </div>
  </div>
  {/* change password accoridan end */}

</div>

    </div>
    <div className='logoutbtn container mt-3'>
      <button className="btn btn-primary" onClick={()=>handleLogout()}>logout</button>
    </div>
    </>
  )
}



// notice react comonent
function Notice(props){
  // increase refresh by 1 or something to refresh 
  const [refresh,setRefresh] = useState(0)
  const navigate = useNavigate()
  useEffect(()=>{
    authfetch(apiurl+"/notice",navigate).then(res=>res.json())
    .then(data=>{
      if (data.show_image){
        document.querySelector(".show_notice_checkbox").checked = data.show_image
      }
    })
    .catch(err=>{
      console.log(err)
    })
    // eslint-disable-next-line
  },[refresh])

  const handleNoticeUpdate = (e) =>{
    e.preventDefault()
    const form = document.querySelector("#noticeForm")
    const image = document.querySelector("#noticeImage").files[0]
    const show_image_bool = document.querySelector(".show_notice_checkbox")
    alert(show_image_bool.checked)
    console.log(show_image_bool)
    const show_image = show_image_bool.checked ? "true" : "false"

    const formData = new FormData()
    formData.append("noticeImage",image)
    formData.append("show_image",show_image)
    
    const headers = {
      "Authorization":localStorage.getItem("token")
    }
    document.querySelector("#progressUpdateNotice").style.display = "";

    axios.request({
      method: "post", 
      url: `${apiurl}/notice`, 
      data: formData, 
      headers:headers,
      onUploadProgress: (event) => {
        const percent = (event.loaded / event.total);
        props.setUploadProgess(percent.toFixed(2));
      }
    }).then (data => {
      console.log(data)
      setRefresh(refresh + 1)
      document.querySelector(".alertbox").innerHTML = Alert(data.data.message,"success")
      document.querySelector("#progressUpdateNotice").style.display = "none"; //hide progress bar after upload
      form.reset()
    })
    .catch(err=>{
      console.log("error occured: ",err)
    })


  }
  return (
    <>
    <form id="noticeForm">
      <span>Select Notice Image</span>
      <div className="my-3">
        <input className="form-control noticeImage" type="file" id="noticeImage" required/>
      </div>
      <div className="form-check form-switch">
        <input className="form-check-input show_notice_checkbox" type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{border: "2px solid #4c4747"}} />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Show Notice</label>
      </div>
      <UploadProgressBar uploadProgressValue={props.uploadProgress} progressBarId="progressUpdateNotice" />
      <button className='btn btn-primary' onClick={(e)=>handleNoticeUpdate(e)}>Update Notice</button>

    </form>
    </>
  )
}


// add Slider Image Component
function Slider(props){

  const handleSliderImageUpload = (e) =>{
    e.preventDefault()
    const form = document.querySelector("#sliderForm")
    const img1 = document.querySelector("#sliderimage1").files[0]
    const img2 = document.querySelector("#sliderimage2").files[0]
    const img3 = document.querySelector("#sliderimage3").files[0]

    // console.log()

    if (!img1 && !img2 && !img3){
      alert("No Image Selected to continue.")
      return
    }

    let imgnames=[];

    const formData = new FormData()
    if (img1){
      formData.append("sliderImages",img1)
      // formData.append("link1",document.querySelector("#link1").value)
      imgnames.push(["image1",img1.name,document.querySelector("#link1").value])
      // formData.append("imagename1","ok")
    }
    if(img2){
      formData.append("sliderImages",img2)
      // formData.append("link2",document.querySelector("#link2").value)
      imgnames.push(["image2",img2.name,document.querySelector("#link2").value])
      // formData.append("image2","ok")

    }
    if(img3){
      formData.append("sliderImages",img3)
      // formData.append("link3",document.querySelector("#link3").value)
      imgnames.push(["image3",img3.name,document.querySelector("#link3").value])
      // formData.append("image3","ok")

    }
    formData.append("imgnames",JSON.stringify(imgnames))
    const headers = {
      "Authorization":localStorage.getItem("token")
    }
    document.querySelector("#progressSliderUpload").style.display = ""; //show progress bar on upload

    axios.request({
      method: "post",
      mode:"no-cors",
      url: `${apiurl}/sliderimages`, 
      data: formData, 
      headers: headers,
      onUploadProgress: (event) => {
        const percent = (event.loaded / event.total);
        props.setUploadProgess(percent.toFixed(2));
      }
    }).then(data => {
      console.log(data);
      alert(data.data.message);
      document.querySelector("#progressSliderUpload").style.display = "none";
      form.reset()
    }).catch(err => {
      console.log("error occurred: ", err);
    });

    

  }

  return (
    <div className='image_uploader'>
      <form id="sliderForm">
      <div className='note'>
        Note: <strong>LinkTo</strong> will redirect to the given url. Leave <strong>LinkTo</strong> blank if you just want to display images 

      </div>
      <div className="mb-3">
        <label htmlFor="sliderimage1" className="form-label">Image 1</label>
        <input className="form-control" type="file" accept='image/*' id="sliderimage1" />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="linkto1">LinkTo</span>
        <input type="text" className="form-control" placeholder="page Url" aria-label="Username" aria-describedby="linkto1" id="link1"/>
      </div>

      <hr />
      <div className="mb-3">
        <label htmlFor="sliderimage2" className="form-label">Image 2</label>
        <input className="form-control" type="file" accept='image/*' id="sliderimage2" />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="linkto2">LinkTo</span>
        <input type="text" className="form-control" placeholder="page Url" aria-label="Username" aria-describedby="linkto2" id="link2" />
      </div>
      <hr />

      <div className="mb-3">
        <label htmlFor="sliderimage3" className="form-label">Image 3</label>
        <input className="form-control" type="file" accept='image/*' id="sliderimage3" />
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="linkto3">LinkTo</span>
        <input type="text" className="form-control" placeholder="page Url" aria-label="Username" id="link3" aria-describedby="linkto3" />
      </div>
      <hr />
      <UploadProgressBar uploadProgressValue={props.uploadProgress} progressBarId="progressSliderUpload" />

      <button className='btn btn-primary' onClick={(e)=>handleSliderImageUpload(e)}>Add Slider Images</button>
      </form>
    </div>
  )
}

// change password component
function ChangePassword(props){
  
  
  const handlePasswordChangeClick = (e) =>{
    e.preventDefault()
    const form = document.querySelector("#changePasswordForm")
    
    if (!form.checkValidity()){
      form.reportValidity()
      return
    }

    const oldPassword = document.querySelector("#oldPassword").value
    const newPassword = document.querySelector("#newPassword").value
    const confirmPassword = document.querySelector("#confirmPassword").value


    if (newPassword !== confirmPassword){
      alert("new password and confirm password is not same")
      return
    }
    if (oldPassword === newPassword){
      alert("old password and new password is same")
      return
    }
    authfetch(apiurl+"/changepassword",props.navigate,{
      method:"POST",
      body: JSON.stringify({oldPassword,newPassword})
    }).then(res=>res.json())
    .then(data=>{
      alert(data.message)
      if (data.status==="ok"){
        form.reset()
      }
    }).catch(err=>{
      alert("password change failed")
      console.log(err)
    })



  }

  return (
    <>
      <form id="changePasswordForm">
        <div className=''>
          <label for="oldPassword" class="form-label">Old Password</label>
          <input type="password" id="oldPassword" class="form-control" aria-labelledby="passwordHelpBlock" required/>
        </div>

        <div className='my-3'>
          <label for="newPassword" class="form-label">New Password</label>
          <input type="password" id="newPassword" class="form-control" aria-labelledby="passwordHelpBlock" required/>
        </div>

        <div className=''>
          <label for="confirmPassword" class="form-label">Confirm Password</label>
          <input type="password" id="confirmPassword" class="form-control" aria-labelledby="passwordHelpBlock" required/>
        </div>

        <button className="btn btn-primary mt-3" onClick={(e)=>handlePasswordChangeClick(e)}>Change Password</button>

        
      </form>
    </>
  )
}