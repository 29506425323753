import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import ItemCard from './ItemCard'
import Footer from './Footer'

export default function RecentView() {
    const nagivate = useNavigate()
    const [recentProduct,setrecentProduct] = useState()

    const handleClearAll = () =>{
        if (localStorage.getItem('recentviews')){
            localStorage.removeItem('recentviews')
        }
        window.location = "/home"

    }
    
    useEffect(()=>{
        if (localStorage.getItem('recentviews')){
            const recentviewsJson = JSON.parse(localStorage.getItem('recentviews'))
            let recentviewsMap = new Map(recentviewsJson)
            const recentviewsArr = Array.from(recentviewsMap)
            const recentviewsObj = Object.fromEntries(recentviewsArr)
            
            console.log(Array.from(Object.values(recentviewsObj)))
            setrecentProduct(Array.from(Object.values(recentviewsObj)))

        }else{
            document.querySelector('.noprod').style.display = "block"
            // nagivate("/home")
        }
        // eslint-disable-next-line
    },[])

  return (
    <>
    <div className="container">
        <h2 className='heading-font inunderline my-3'>Recently Viewed Products</h2>
        <span className='font-16 cursor-pointer mx-1 hover-underline' onClick={handleClearAll}>Clear All</span>

        <div className='noprod' style={{display:"none"}}>You havent Viewed any products yet</div>
        <div className='row row-cols-md-4 row-cols-lg-6 row-cols-2' style={{minHeight:"40vh"}}>
            {recentProduct && recentProduct.map(elem=>(
                <div className='col mb-4' key={elem._id}>
                <ItemCard img={elem}/>

                </div>
            ))}
        </div>
        
    </div>
    <Footer />
    </>
  )
}
